import React, { useRef } from "react";
import "./header.css";

function Headerss() {
  const scrollToHeight = (height) => {
    document.documentElement.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };

  return (
    <div className="headers">
      <div className="containers">
        <ul className="lists">
          <li>
            <a href="#" onClick={() => scrollToHeight(0)}>
              Home
            </a>
          </li>
          <li>
            <a href="" onClick={() => scrollToHeight(500)}>
              About
            </a>
          </li>
          <li>
            <a href="#">Projects</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Headerss;
