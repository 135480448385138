import "./App.css";
import Contact from "./pages/Contact/Contact";
import Footer from "./pages/Footer/Footer";
import "./sources/bootstrap.css";
import About from "./pages/about/About";
import Banner from "./pages/banner/Banner";
import Headerss from "./pages/header/Headerss";
import Projects from "./pages/projects/Projects";

import "../src/sources/css/plugins.css";
import Bannersss from "./pages/banner/Bannersss";
import Aboutss from "./pages/about/Aboutss";
import Projectss from "./pages/projects/Projectss";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      {/* <Headerss /> */}
      <Bannersss />
      {/* <Banner /> */}
      {/* <About /> */}
      <Aboutss />
      <Projects />
      {/* <Projectss /> */}
      <Contact />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
