import React, { useEffect, useState } from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Parallax } from "react-parallax";
import image from "../../sources/img/slider/21.jpg";
function Bannersss() {
  const scrollToHeight = (height, e) => {
    e.preventDefault();
    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrolled(scrollPosition > 1);
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Parallax strength={400} bgImage={image}>
        <div className="bannerss">
          <div className="header_div">
            <div
              // className="containers header_divs"
              className={`header_divs containers ${scrolled ? "scrolled" : ""}`}
            >
              <ul className="lists">
                <li>
                  <a href="#" onClick={(e) => scrollToHeight(0, e)}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e) => scrollToHeight(900, e)}>
                    About
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e) => scrollToHeight(1800, e)}>
                    Projects
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e) => scrollToHeight(5000, e)}>
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <h1 className="banner_title ">Full Stack Software Engineer</h1> */}
          <div className="banner_div">
            <h3 className="banner_title name">Hello! I'm Embet.</h3>
            <h3 className="banner_title green">
              <span className="title_banner">
                {" "}
                Full Stack Software Engineer
              </span>
            </h3>
            <p className="p_banner">
              I'm a passionate developer, creating everything from how it looks
              to how it works behind the scenes.
            </p>
          </div>
          {/* <div className="Bannerbuttons container">
          <a href="https://www.linkedin.com/in/embet-mengistu" target="blank">
            <LinkedInIcon style={{ color: "#0B65C2" }} />
          </a>
          <a href="https://github.com/embet-mengistu/" target="blank">
            <GitHubIcon />
          </a>
          <div className="secondBannerSection"></div>
        </div>{" "} */}
        </div>
      </Parallax>
    </div>
  );
}

export default Bannersss;
