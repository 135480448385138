import React, { useState } from "react";
import { Parallax } from "react-parallax";
import myPic from "../../sources/img/mypic3.png";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import "./about.css";
import bg from "../../sources/img/slider/33.jpg";
import gitHubLogo from "../../sources/img/logo/icons8-github-24.png";
import linkinLogo from "../../sources/img/logo/icons8-linkedin-24.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

function Aboutss() {
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);

  // const handleClick = () => {
  //   setIsPhoneVisible(!isPhoneVisible);
  // };
  const handlePhoneClick = () => {
    window.location.href = "tel:+18572096616";
  };
  return (
    <div className="About">
      <div className="container about_container" id="about">
        <Parallax strength={200} bgImage={bg}>
          <div className="beny_tm_title_holder">
            <span>About Me</span>
            <h3>About Me</h3>
            <p style={{ marginBottom: "50px" }}>
              Motivated and dynamic Web Developer with a keen focus and
              experience in web application development. I possess a strong work
              ethic and an innate ability to adapt in ever-changing and
              challenging environments. My passion for creating innovative and
              user-friendly solutions drives my commitment to staying updated
              with the latest technologies.
            </p>
          </div>
          {/* /////////// */}
          <div className="pic_desc">
            <div className="mypicImage">
              <img src={myPic} alt="" />
            </div>

            {/* ////////// */}
            <div className="about_inner">
              <div
                className="right wow fadeInLeft"
                data-wow-duration="1.2s"
                data-wow-delay="0.3s"
              >
                <div className="short">
                  <h3 className="hiThere">Hi There!</h3>
                  <p className="desc">
                    As a Full Stack Software Engineer, I am dedicated to
                    consistently delivering top-tier, user-friendly experiences.
                    My expertise encompasses both front-end and back-end
                    development, enabling me to excel in the creation of dynamic
                    and responsive web applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
        <div className="display_none">
          <div className="eww">
            <div className="mypicImages">
              <img src={myPic} alt="" />
            </div>
            <div className="shorts">
              <h3 className="hiThere">Hi There!</h3>
              <p className="descs">
                As a Full Stack Software Engineer, I am dedicated to
                consistently delivering top-tier, user-friendly experiences. My
                expertise encompasses both front-end and back-end development,
                enabling me to excel in the creation of dynamic and responsive
                web applications.
              </p>
              <div className="piclinks_about">
                <div className="linkedin">
                  <a href="https://github.com/embet-mengistu/" target="_blank">
                    <img src={gitHubLogo} alt="" />
                  </a>
                </div>
                <div className="githubs">
                  <a
                    href="https://www.linkedin.com/in/embet-mengistu"
                    target="_blank"
                  >
                    <img src={linkinLogo} alt="" />
                  </a>
                </div>
                <div style={{ marginLeft: "15px", display: "flex" }}>
                  <span
                    onClick={handlePhoneClick}
                    class=""
                    style={{ color: "white" }}
                  >
                    <LocalPhoneIcon class="icons" fontSize="large" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ////// */}
      </div>
    </div>
  );
}

export default Aboutss;
