import React from "react";
import "./contact.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Parallax } from "react-parallax";
import bg from "../../sources/img/slider/33.jpg";
import gitHubLogo from "../../sources/img/logo/icons8-github-24.png";
import linkinLogo from "../../sources/img/logo/icons8-linkedin-24.png";

function Contact() {
  return (
    <Parallax strength={1500} bgImage={bg}>
      <div>
        <div className="beny_tm_contact" id="contact">
          <div className="container">
            <div className="contact_inner">
              <div className="left wow fadeInLeft" data-wow-duration="1.2s">
                <div className="beny_tm_title_holder">
                  <span>Contact</span>
                  <h3>Let's discuss your project</h3>
                </div>
                <div className="short_list">
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <span class="eww_container">
                        <LocalPhoneIcon class="icons" />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <a href="tel: +1 (857)209-6616"> +1 (857)209-6616</a>
                      </span>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      {/* <img class="svg" src="img/svg/mail.svg" alt="" /> */}
                      <span class="eww_container">
                        <EmailIcon
                          className="icons"
                          style={{ fontSize: "small" }}
                        />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <a href="mailto:embetm27@gmail.com">
                          embetm27@gmail.com
                        </a>
                      </span>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      {/* <img className="svg" src="img/svg/map.svg" alt="" /> */}
                      <span class="eww_container">
                        <LocationOnIcon
                          className="icons"
                          style={{ fontSize: "small" }}
                        />
                      </span>

                      <span style={{ marginLeft: "10px", color: "#7e7e7e" }}>
                        United States
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <hr style={{ borderTop: "1px solid " }} className="hr_footer" />
            <div className="footer_container">
              <p style={{ fontSize: "10px" }}>
                Copyright &copy; 2021. All rights are reserved.
              </p>
              <div className="piclinks">
                <div className="linkedin">
                  <a href="https://github.com/embet-mengistu/" target="_blank">
                    <img src={gitHubLogo} alt="" />
                  </a>
                </div>
                <div className="github">
                  <a
                    href="https://www.linkedin.com/in/embet-mengistu"
                    target="_blank"
                  >
                    <img src={linkinLogo} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default Contact;
