import React from "react";
import gitHubLogo from "../../sources/img/logo/icons8-github-24.png";
import linkinLogo from "../../sources/img/logo/icons8-linkedin-24.png";
import "./footer.css";

function Footer() {
  return (
    <div>
      <div className="beny_tm_copyright">
        <div className="container">
          <div className="inner">
            <div className="copy wow fadeInLeft" data-wow-duration="1.2s">
              <div className="piclinks"></div>
              <p style={{ fontSize: "10px" }}>
                Copyright &copy; 2021. All rights are reserved.
              </p>
            </div>
            <div>
              <div className="copy wow fadeInLeft" data-wow-duration="1.2s">
                <div className="piclinks">
                  <div className="linkedin">
                    <a
                      href="https://github.com/embet-mengistu/"
                      target="_blank"
                    >
                      <img src={gitHubLogo} alt="" />
                    </a>
                  </div>
                  <div className="github">
                    <a
                      href="https://www.linkedin.com/in/embet-mengistu"
                      target="_blank"
                    >
                      <img src={linkinLogo} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ borderTop: "1px solid red" }} />
    </div>
  );
}

export default Footer;
