import React from "react";
import netfilxLogo from "../../sources/img/netflix.jpg";
import amazonLogo from "../../sources/img/amazon.jpg";
import zoomLogo from "../../sources/img/zoom.jpg";
import appleLogo from "../../sources/img/apple.jpg";
import evangadiLogo from "../../sources/img/evangadiForum.jpg";
import instagramLogo from "../../sources/img/slider/instagram.png";
import whtsAppLogo from "../../sources/img/slider/whtsUpback.png";
import "./projects.css";
import { Parallax } from "react-parallax";
import bg from "../../sources/img/slider/33.jpg";

function Projects() {
  return (
    <Parallax style={200} bgImage={bg} className="parallax">
      <div className="kk">
        <div className="beny_tm_portfolio" id="portfolio">
          <div className="container">
            <div className="beny_tm_title_holder">
              <span>Portfolio</span>
              <h3>Recent Projects</h3>
            </div>
            <div className="portfolio_list">
              <ul className="gallery_zoom">
                <div className="firstRow">
                  <li className="wow fadeInLeft" data-wow-duration="1.2s">
                    <div className="list_inner" style={{}}>
                      <img className="placeholder" src={netfilxLogo} alt="" />
                      <div
                        className="main"
                        data-img-url={netfilxLogo}
                        style={{ backgroundImage: `url(${netfilxLogo})` }}
                      ></div>
                      <div className="overlay"></div>

                      <div className="title">
                        <h3 className="project_name">Netflix</h3>
                        <span class="beny_tm_button buton">
                          <a
                            href="https://github.com/embet-mengistu/Netflix-clone"
                            target="_blank"
                          >
                            Github
                          </a>
                          <span style={{ paddingLeft: "5px" }}></span>
                          <a
                            href="https://netflix-clone-5ba65.web.app/"
                            target="_blank"
                          >
                            Website
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="wow fadeInLeft" data-wow-duration="1.2s">
                    <div className="list_inner">
                      <img className="placeholder" src={instagramLogo} alt="" />
                      <div
                        className="main"
                        data-img-url={instagramLogo}
                        style={{ backgroundImage: `url(${instagramLogo})` }}
                      ></div>
                      <div className="overlay"></div>

                      <div className="title">
                        <h3>Instagram</h3>
                        <span className="beny_tm_button buton">
                          <a
                            href="https://github.com/embet-mengistu/instagram"
                            target="_blank"
                          >
                            Github
                          </a>
                          <span style={{ paddingLeft: "5px" }}></span>
                          {/* <a
                            href="https://instagram-clone-eb296.firebaseapp.com/"
                            target="_blank"
                          >
                            Website
                          </a> */}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="wow fadeInLeft" data-wow-duration="1.2s">
                    <div className="list_inner">
                      <img className="placeholder" src={amazonLogo} alt="" />
                      <div
                        className="main"
                        data-img-url={amazonLogo}
                        style={{ backgroundImage: `url(${amazonLogo})` }}
                      ></div>
                      <div className="overlay"></div>

                      <div className="title">
                        <h3>Amazon</h3>

                        <span class="beny_tm_button buton">
                          <a
                            href="https://github.com/embet-mengistu/Amazon-frontend"
                            target="_blank"
                          >
                            Github
                          </a>
                          <span style={{ paddingLeft: "5px" }}></span>
                          <a
                            href="https://frontend-2f1bf.firebaseapp.com/"
                            target="_blank"
                          >
                            Website
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                </div>

                <div className="secondRow">
                  <li class="wow fadeInLeft" data-wow-duration="1.2s">
                    <div class="list_inner">
                      <img
                        class="placeholder"
                        src="img/whtsUpback.png"
                        alt=""
                      />
                      <div
                        class="main"
                        data-img-url="img/whtsUpback.png"
                        style={{ backgroundImage: `url(${whtsAppLogo})` }}
                      ></div>
                      <div class="overlay"></div>

                      <div class="title">
                        <h3>WhatsApp</h3>
                        <span class="beny_tm_button buton">
                          <a
                            href="https://github.com/embet-mengistu/whtsApp-clone"
                            target="_blank"
                          >
                            Github
                          </a>
                          <span style={{ paddingLeft: "5px" }}></span>
                          <a
                            href="https://whtsapp-clone-3ca83.web.app/"
                            target="_blank"
                          >
                            Website
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="wow fadeInLeft" data-wow-duration="1.2s">
                    <div className="list_inner">
                      <img className="placeholder" src={amazonLogo} alt="" />
                      <div
                        className="main"
                        style={{ backgroundImage: `url(${appleLogo})` }}
                      ></div>
                      <div className="overlay"></div>

                      <div className="title">
                        <h3>Apple</h3>
                        <span class="beny_tm_button buton">
                          <a
                            href="https://github.com/embet-mengistu/apple"
                            target="_blank"
                          >
                            Github
                          </a>
                          <span style={{ paddingLeft: "5px" }}></span>
                          <a
                            href="https://steady-syrniki-e2030f.netlify.app/"
                            target="_blank"
                          >
                            Website
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li className="wow fadeInLeft" data-wow-duration="1.2s">
                    <div className="list_inner">
                      <img className="placeholder" src={zoomLogo} alt="" />
                      <div
                        className="main"
                        style={{ backgroundImage: `url(${zoomLogo})` }}
                      ></div>
                      <div className="overlay"></div>

                      <div className="title">
                        <h3>Zoom</h3>
                        <span class="beny_tm_button buton">
                          <a
                            href="https://github.com/embet-mengistu/zoom-clone"
                            target="_blank"
                          >
                            Github
                          </a>
                          <span style={{ paddingLeft: "5px" }}></span>
                        </span>
                      </div>
                    </div>
                  </li>
                </div>
                <li className="wow fadeInLeft" data-wow-duration="1.2s">
                  <div className="list_inner">
                    <img className="placeholder" src={evangadiLogo} alt="" />
                    <div
                      className="main"
                      style={{ backgroundImage: `url(${evangadiLogo})` }}
                    ></div>
                    <div className="overlay"></div>

                    <div className="title">
                      <h3>Evangadi</h3>
                      <span class="beny_tm_button buton">
                        <a
                          href="https://github.com/embet-mengistu/evangadi-backend"
                          target="_blank"
                        >
                          Github
                        </a>
                        <span style={{ paddingLeft: "5px" }}></span>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default Projects;
